<template>
  <div :class="['grid grid-cols-3 md:grid-cols-4 gap-2', data.Class]">
    <template v-for="(d, i) in data.Data">
      <b-tooltip v-if="d.Tooltip" position="is-bottom" multilined :key="i">
        <image-item :src="d.Image" :href="d.Url" :desc="d.Description"></image-item>
        <template v-slot:content>
          <span v-if="d.Tooltip.TextHTML" v-html="d.Tooltip.TextHTML" />
          <span v-else>{{d.Tooltip.Text}}</span>
        </template>
      </b-tooltip>
      <image-item v-else :key="i" :src="d.Image" :href="d.Url" :desc="d.Description"></image-item>
    </template>
  </div>
</template>

<script>
import PreviewImage from '../PreviewImage'

const ImageItem = {
  props: {
    src: String,
    href: {
      type: String,
      required: false
    },
    desc: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      preview: false
    }
  },
  render (h) {
    const img = [h('img', { style: this.imgStyle, attrs: { src: this.src } })]

    if (this.desc) {
      img.push(
        h('p', { domProps: { innerHTML: this.desc }, class: 'text-gray-700' })
      )
    }

    const divProps = { class: this.divClasses }

    if (!this.href && !this.preview) {
      divProps.on = {
        click: () => {
          this.preview = true
        }
      }
    }

    if (this.href) {
      return h('a', { attrs: { href: this.href } }, [
        h('div', divProps, [img])
      ])
    }

    return h('div', divProps, [
      img,
      h(PreviewImage, {
        props: { value: this.preview, img: this.src, downloadable: true },
        on: {
          input: (value) => {
            this.preview = value
          }
        }
      })
    ])
  },
  computed: {
    imgStyle () {
      return {
        height: '150px',
        objectFit: 'cover'
      }
    },
    divClasses () {
      return 'shadow p-2 flex flex-col items-center'
    }
  }
}

export default {
  components: {
    ImageItem
  },
  props: {
    data: Object
  }
}
</script>

<style>

</style>
